import React from "react"
import IconWhite from '../images/naideia-bee-white.svg'
import IconGray from '../images/naideia-bee-gray.svg'

function renderNav() {
    return <nav className="nav clearfix">
    <div className="nav-inner">
        <a href="/"><div className="nav-logo" /></a>
        <div className="nav-links">
            <a className="nav-item" href="/#releases">Releases</a>
            <a className="nav-item" href="/#newsletter">Newsletter</a>
            <a className="nav-item" href="/about">About</a>
        </div>
    </div>
    </nav>;
}

export default renderNav;
